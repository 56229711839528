import React, { Component } from "react"
import AUTOM from "../../../images/automation1.png"

import styled from "styled-components"
import { loginUrl } from "../../../config/keys"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
class BoxOne extends Component {
  render() {
    return (
      <MainBox>
        <Box>
          <BoxLeft>
            <BoxLeftMainHeader>
              Eliminate busy-ness out of your business
            </BoxLeftMainHeader>
            <br />

            <BoxLeftContent>
              {" "}
              Eliminate repetitive tasks by automating your Sales Value chain.
              Capture leads and route them to your reps Automatically. Add and
              assign tasks to have total control over all your opportunities.
              Automate sending your emails quotations, follow-ups, reminders
              also, manage approvals and escalations with easy to use
              configurable processes, workflows.
            </BoxLeftContent>
            <BoxLeftButton>
              <TrialLink1
                onClick={(e) => {
                  // To stop the page reloading
                  // e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "start your free trial  button in navbar ",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Start your free trial button in navbar",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 43,
                  })
                  //... Other logic here
                }}
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                Get Started
              </TrialLink1>
            </BoxLeftButton>
          </BoxLeft>
          <BoxRight>
            <img src={AUTOM} alt="automation" width="100%" />
          </BoxRight>
        </Box>
      </MainBox>
    )
  }
}
export default BoxOne
const MainBox = styled.div`
  margin-top: 82px;
  background: #f8f8f8;
`
const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  /* margin-top: 20px; */
`

const BoxLeft = styled.div`
  /* border: 1px solid black; */
  width: 47%;
  margin: 20px 100px;
  padding: 40px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0px;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 3em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #2a1e5c;
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  font-weight: 100;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftButton = styled.div`
  padding: 6px 1px;
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  background: #29c8ff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 100;
  font-size: 1.25em;
  width: 148px;
  height: 58px;
  color: #ffffff !important;
`

const BoxRight = styled.div`
  width: 42%;
  padding: 19px 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
