import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Approvalsandescalation from "../modules/SubFetaures/Approvalsandescalation"

const automation = () => (
  <Layout>
    <SEO
      title="Approvals and Escalation"
      description="Easily configure processes using rules and automate the processes to manage Approvals and Escalations. 
    Also configure and automate e-mails and notifications so that users are updated in time."
    />
    <Approvalsandescalation />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default automation
