import React from "react"
import BoxOne from "./AutomationNewPage/BoxOne"
import BoxTwo from "./AutomationNewPage/BoxTwo"
import BoxThree from "./AutomationNewPage/BoxThree"
import BoxFour from "./AutomationNewPage/BoxFour"
import styled from "styled-components"
function Approvalsandescalation() {
  return (
    <div>
      <BoxOne />
      <BoxTwo />
      <Spacer />
      <BoxThree />
      <BoxFour />
    </div>
  )
}

export default Approvalsandescalation
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
