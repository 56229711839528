import React, { Component } from "react"
import AUTOMATIONFIRSTIMAGE from "../../../images/automations/first.jpg"
import AUTOMATIONSECONDIMAGE from "../../../images/automations/second.jpg"
import AUTOMATIONTHIRDIMAGE from "../../../images/automations/third.jpg"
import AUTOMATIONFOURTHIMAGE from "../../../images/automations/fourth.jpg"
// import OUTOFBOXTHREESECOND from "../../../images/outofboxthreesecond.png"
import CIRCLETICK from "../../../images/greencircletick.png"

import styled from "styled-components"
class BoxThree extends Component {
  render() {
    return (
      <MainBox>
        {/* Out Of The Box */}
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img
                src={AUTOMATIONFIRSTIMAGE}
                alt="firstimage"
                width="100%"
                height="100%"
              />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Turn conversations into customers
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Bring all your leads in one place to keep your sales process
                uncluttered and focused on opportunities. Follow up, qualify new
                leads with powerful rules and automations instantly.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Capture and route leads with powerful rule configurations
                to get the ball rolling
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Set up rules to distribute and add new leads from website
                to your sales reps funnels directly.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Configure rules for ageing, instantly notify your reps
                about leads that fall under
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Redistribute unqualified aged leads leads amongst reps as
                per your need.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={AUTOMATIONFIRSTIMAGE} alt="firstimage" width="100%" />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={AUTOMATIONSECONDIMAGE} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              {" "}
              <img src={AUTOMATIONSECONDIMAGE} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRight>

          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>Quote to win more fast</BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Generate polished quotes in seconds. Set up automations to send
                quotes when needed and close more opportunities fasters.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp;Create quotes using Pre-Built Templates or simply start
                creating yours.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Configure rules for sending quotes to internal and
                external stakeholders.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Automate sending emails and notifications
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={AUTOMATIONTHIRDIMAGE} alt="thirdimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                No more waiting for approvals
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                No more waiting on approvals or wasting time on approving.
                Configure your approval process easily with powerful rules for
                faster turnarounds and close more opportunities on the fly.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Access complete task history, documents and information
                for faster turnarounds and close opportunities faster.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Create multiple templates for emails and notifications.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Define and automate approvals based on threshold proposal
                value and notify stakeholders instantly.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={AUTOMATIONTHIRDIMAGE} alt="thirdimage" width="100%" />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={AUTOMATIONFOURTHIMAGE} alt="fourth.jpg" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              <img src={AUTOMATIONFOURTHIMAGE} alt="fourth.jpg" width="100%" />
            </ImageBox>
          </BoxRight>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Heighten your team's performance
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Define rules to bring opportunities to closure faster. Empower
                your reps with everything they need to sell better, faster. Set
                labels and priorities for each opportunity. Everyone stays on
                the same page without missing out on key tasks and activities,
                ensuring each customer is serviced in time, always.
              </BoxLeftContent1>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />
      </MainBox>
    )
  }
}
export default BoxThree

const ContentWrapper = styled.div`
  /* border: 3px solid yellow; */
`

const MainBox = styled.div`
  padding: 40px;
  /* border: 1px solid red; */
  /* background: #eafaff; */
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0px;
  }

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  width: 45%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 2.2em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #080612;
`
const BoxLeftContent1 = styled.p`
  font-family: "Roboto";
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  padding: 10px 0px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  padding: 0px 10px;
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const BoxRight = styled.div`
  width: 52%;
  /* padding: 0px 30px;
  margin-right: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  @media screen and (max-width: 600px) {
    width: 100%;
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ImageBox = styled.div`
  border: 11px solid rgb(221, 221, 221);
  border-radius: 7px;
  /* height: 480px; */
  background-color: whitesmoke;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    /* height: 220px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* height: 320px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* height: 420px; */
    margin: 20px 10px;
  }
`
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 70px;
`
const BoxRightMobile = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
